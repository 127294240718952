import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import PageLayout from "../../components/Layout/PageLayout";
import PageBlock from "../../components/Layout/PageBlock";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { staticImgContainer } from "../../styles/component-stylings";

const PAGE_TITLE = "Legislation";

const LegislationPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 65%" }}
                    src='../../images/legal.jpg'
                    alt='Legislation'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>Due to the sensitive nature of our work, we ensure that we adhere to government legislation.</p>
                <p>We work towards the following legislations:</p>
                <ul>
                    <li>
                        <a href='https://www.legislation.gov.au/Details/C2022C00021'>Surveillance Devices Act 2004</a>
                    </li>
                    <li>
                        <a href='https://www.legislation.gov.au/Details/C2022C00067'>Telecommunications (Interception and Access) Act 1979</a>
                    </li>
                    <li>
                        <a href='https://www.legislation.gov.au/Details/C2021A00098'>Surveillance Legislation Amendment (Idenfify and Disrupt) Act 2021</a>
                    </li>
                </ul>
            </PageBlock>
        </PageLayout>
    );
};

export default LegislationPage;
